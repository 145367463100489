@import "variable";

app-home, app-terms, contactmessage, videoyoutubeatom, slider {
  width: 100%;
}

iframe {
  box-shadow: none;
  border: none;
}

table {
  border-collapse: collapse;
  border-radius: rem(5);

  tr {

    th {
      font-size: rem(13);
      font-weight: 500;
      text-align: left;
      background: #F4F5F7;
      border: rem(1) solid #DBE0E8;
      padding: rem(18) rem(16);
    }

    td {
      padding: rem(12) rem(14);
      font-size: rem(12);
      font-weight: 400;
      text-align: left;
      border: rem(1) solid #DBE0E8;
      white-space: break-spaces;
    }
  }
}

.headline {
  b {
    font-weight: 500;
    color: #0080C3;
  }
}

// Overwrite strong in cms-html-renderer
ul {
  li {
    .text-blue {
      color: #0080C3;
    }
  }
}


.dp-material {

  .dp-day-calendar-container {

    dp-calendar-nav {

      .dp-calendar-nav-container {
        border: none;

        .dp-nav-header {

          & > button {
            color: #878787;
            font-weight: 400;
            font-size: rem(14);
          }
        }

        .dp-nav-btns-container {

          .dp-calendar-nav-container-left {

            & > button::before {
              color: #878787 ;
            }

          }

          .dp-calendar-nav-container-right {

            & > button::before {
              color: #878787 ;
            }

          }
        }
      }
    }

    .dp-calendar-wrapper {
      border: none;
      display: flex;
      flex-direction: column;
      gap: rem(10);

      .dp-weekdays {
        display: flex;
        justify-content: space-around;
      }

      .dp-calendar-week {
        display: flex;
        justify-content: space-around;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {

  table {
    width: 100%;
    overflow-y: scroll;

    tbody {
      width: 100%;
    }
  }

  .dp-material {

    .dp-day-calendar-container {

      dp-calendar-nav {

        .dp-calendar-nav-container {
          border: none;
          position: relative;

          .dp-nav-header {
            display: flex;
            width: 100%;
            justify-content: center;

            & > button {
              color: #000730;
              cursor: not-allowed;
              font-weight: 400;
              font-size: rem(14);
            }
          }

          .dp-nav-btns-container {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .dp-calendar-nav-container-left {

              & > button::before {
                color: #000730 ;
              }

            }

            .dp-calendar-nav-container-right {

              & > button::before {
                color: #000730 ;
              }

            }
          }
        }
      }
    }
  }

}
